<template>
  <b-container>
    <h2>secrets</h2>
    <b-row v-for="s in secrets" :key="s._id">
      <b-col>{{s.title}}</b-col>
      <b-col>
        <b-row>
          <div class="icon-btn" @click="editSecret(s._id)"><i class="fas fa-pencil-alt"/></div>
          <div class="icon-btn" @click="openAuthCodeModal(s._id)"><i class="fas fa-qrcode"/></div>
        </b-row>
      </b-col>
    </b-row>
    <div class="mt-2" style="cursor: pointer;" @click="openSecretModal">create</div>
    <h2 class="mt-4">auth codes</h2>
    <b-row v-for="c in codes" :key="c._id">
      <b-col>{{c.name || 'noname'}}</b-col>
      <b-col>{{ getTitle(c) }}</b-col>
      <b-col>{{c.accessDates.length}}</b-col>
      <b-col style="cursor:pointer;" @click="deleteAuthCode(c._id)"><b-icon icon="x" /></b-col>
    </b-row>
    <b-modal id="create-auth-code-1" title="generate auth code" centered hide-footer>
      <b-input v-if="!code" placeholder="code name" v-model="name" />
      <b-button v-if="!code" @click="createAuthCode">Generate</b-button>
      <qrcode-vue v-if="code" class="qr"
        :value="code.url" level="H" size="150" background="transparent" render-as="svg" />
      <b-button v-if="code" v-clipboard:copy="code.url"><b-icon icon="clipboard"/></b-button>
    </b-modal>
    <b-modal id="create-secret-1" title="create secret" centered hide-footer>
      <b-input placeholder="title" v-model="secretTitle" />
      <b-button @click="createSecret">Create</b-button>
    </b-modal>
  </b-container>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Secrets',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      name: '',
      secrets: [],
      codes: [],
      secretId: '',
      code: false,
      secretTitle: '',
    };
  },
  async mounted() {
    this.getSecrets();
    this.getAuthCodes();
  },
  methods: {
    async getSecrets() {
      const token = await localStorage.getItem('token')
      this.axios
        .get('secret/list', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.secrets = r.data;
        })
        .catch(() => {
        })
    },
    async getAuthCodes() {
      const token = await localStorage.getItem('token')
      this.axios
        .get('authcode/list', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.codes = r.data;
        })
        .catch(() => {
        })
    },
    async createSecret() {
      const token = await localStorage.getItem('token')
      this.axios
        .post('secret/create', {
          title: this.secretTitle,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.editSecret(r.data._id);
        })
        .catch(() => {
        })
    },
    editSecret(id) {
      this.$router.push(`/secrets/${id}`);
    },
    openAuthCodeModal(id) {
      this.secretId = id;
      this.code = false;
      this.$bvModal.show('create-auth-code-1');
    },
    openSecretModal() {
      this.$bvModal.show('create-secret-1');
    },
    async createAuthCode() {
      const token = await localStorage.getItem('token')
      this.axios
        .post("authcode/create", {
          secret: this.secretId,
          name: this.name,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((r) => {
            this.code = r.data;
          })
          .catch((/*err*/) => {
          });
    },
    async deleteAuthCode(id) {
      const token = await localStorage.getItem('token')
      this.axios
        .delete("authcode/delete", {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            this.getAuthCodes();
          })
          .catch((/*err*/) => {
          });
    },
    getTitle(c) {
      if (!this.secrets) return;
      const secret = this.secrets.find((e) => e._id === c.secret);
      if (!secret) return 'no secret';
      return secret.title || 'no title';
    }
  },
}
</script>

<style scoped>

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr {
  margin: 5rem;
}

.icon-btn {
  cursor: pointer;
  margin-left: 1em;
  margin-right: 1em;
}

</style>